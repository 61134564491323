import React, { Fragment, useRef, useMemo, useCallback, useEffect, useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { FiEdit2, FiMail, FiMapPin, FiPhoneCall } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { IoNotificationsOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Form, Formik, useFormik } from 'formik';
import { FiChevronRight } from 'react-icons/fi';
import Modal from 'react-responsive-modal';
import { BiReceipt } from 'react-icons/bi';
import { cloneDeep, groupBy } from 'lodash';
import { Scrollbars } from 'rc-scrollbars';
import * as yup from 'yup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'react-notifications/lib/notifications.css';

import api from 'services/api';
import config from 'helpers/config';
import { pathnames } from 'routes/routes';
import { useIsMount } from 'hooks/use-mount';
import Button from 'components/button/button';
import BreadCrumb from 'components/breadcrumb/breadcrumb';
import Dropdown from 'components/inputs/dropdown/dropdown';
import { getUserProfile } from 'redux/actions/user-actions';
import TextInput from 'components/inputs/text-input/text-input';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import DeliveryFeeModal from 'components/delivery-fee-modal/delivery-fee-modal';
//prettier-ignore
import { formatCurrency, getVariationStatus, validMobileNumbers, getStringAddress } from 'helpers/utility';
import outOfStock from 'assets/notfound/out-of-stock.png';

const getShippingOptionClassName = ({ error }) => {
    //prettier-ignore
    const classNames = ['m-txt', 'm-txt--bold', 'checkout__courier-button'];
    if (error) classNames.push('m-txt--error');
    if (!error) classNames.push('m-txt--grey');
    return classNames.join(' ');
};

const validateSchema = yup.object().shape({
    addressLineOne: yup.string().required('Address line one is required.'),
    addressLineTwo: yup.string().required('Address line two is required.'),
    addressLineThree: yup.string(),
    addressLineFour: yup.string(),
    country: yup.string().required('Country is required.'),
    state: yup.string().required('State is required.'),
    city: yup.string().required('City is required.'),
    postcode: yup.string().required('Postcode is required.'),
    mobileNumber: yup
        .string()
        .test(
            'mobileNumber',
            'Please enter a valid mobile number (e.g 60123456789) ',
            validMobileNumbers
        )
        .required('Mobile number is required'),
});

const CheckoutPage = (props) => {
    const deliveryFeeModalRef = useRef();
    const history = useHistory();
    const { state } = useLocation();
    const isMount = useIsMount();
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.user);
    const { address, id } = useSelector((state) => state.user.profile);
    const [deliveryAddress, setDeliveryAddress] = useState(profile.deliveryAddress);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [errorModalVisibility, setErrorModalVisibility] = useState(false);
    const [errorCode, setErrorCode] = useState();
    const [onSubmit, setOnSubmit] = useState(false);
    const [countries, setCountries] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const onHandleGetUserProfile = useCallback((o) => dispatch(getUserProfile(o)), [dispatch]);
    const userDeliveryAddress = useMemo(() => {
        const userAddress = cloneDeep(address);
        if (countries.length) {
            const country = countries.filter((o) => o.value == userAddress.country)[0].label;
            userAddress.country = country;
            if (userAddress) {
                return getStringAddress(userAddress);
            } else {
                return 'Please provide your delivery address.';
            }
        } else {
            return 'Getting your delivery address...';
        }
    }, [address, countries]);
    const enrichedUserProfileAddress = useMemo(() => {
        const userAddress = cloneDeep(address);
        if (countries.length) {
            const country = countries.filter((o) => o.value == userAddress.country)[0].label;
            userAddress.country = country;
        }
        return userAddress;
    }, [address, countries]);
    const courierServiceSchema = useMemo(() => {
        const validation = {};
        const values = {};
        cartItems?.forEach((carts) => {
            carts?.forEach((item) => {
                values[item.companyId] = '';
                validation[item.companyId] = yup.object().required('This field is required');
            });
        });
        return { validationSchema: yup.object().shape(validation), values };
    }, [cartItems]);
    const courierServiceForm = useFormik({
        enableReinitialize: true,
        initialValues: courierServiceSchema.values,
        // validationSchema: courierServiceSchema.validationSchema,
        onSubmit: (values) => {
            onHandleCreateOrder(values);
        },
    });
    const totalAmountToPay = useMemo(() => {
        let total = state.checkout.total;
        const couriers = courierServiceForm.values;

        for (var key in couriers) {
            if (couriers.hasOwnProperty(key)) {
                if (couriers[key].price) total += couriers[key].price;
            }
        }

        return total;
    }, [courierServiceForm]);
    const initialValues = {
        name: profile?.displayName || '',
        mobileNumber: profile?.mobileNumber || '',
        addressLineOne: address?.addressLineOne || '',
        addressLineTwo: address?.addressLineTwo || '',
        addressLineThree: address?.addressLineThree || '',
        addressLineFour: address?.addressLineFour || '',
        city: address?.city || '',
        state: address?.state || '',
        postcode: address?.postcode || '',
        country: address?.country || '',
        addressType: address?.addressType || '',
    };

    useEffect(() => {
        enrichCartItems();
    }, []);

    const enrichCartItems = () => {
        let groupedCartItems = groupBy(state?.checkout?.orders, 'companyId');
        groupedCartItems = Object.keys(groupedCartItems).map((key) => groupedCartItems[key]);
        setCartItems(groupedCartItems);
    };

    const onHandleGetCountries = useCallback(async () => {
        try {
            const response = await api.get.countries();
            const mappedCountries = response.data.result.map((o) => ({
                label: o.country,
                value: o.id,
            }));
            const mysiaIndex = mappedCountries.findIndex(
                (o) => o.label.toLowerCase() === 'malaysia'
            );
            mappedCountries.unshift(mappedCountries[mysiaIndex]);
            mappedCountries.splice(mysiaIndex);
            setCountries(mappedCountries);
            updateAddress(mappedCountries);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const updateAddress = (mappedCountries) => {
        const oldCountry = '"country":' + JSON.parse(deliveryAddress)['country'];
        const newCountry =
            '"country":' +
            JSON.stringify(
                mappedCountries.filter((x) => x.value === JSON.parse(deliveryAddress)['country'])[0]
                    ?.label
            );
        const newAddress = deliveryAddress.replace(oldCountry, newCountry);
        const newAddress2 = newAddress.replace(',"addressType":""', '');
        setDeliveryAddress(newAddress2);
    };

    useEffect(() => {
        if (isMount) {
            onHandleGetCountries();
        }
    }, [isMount, onHandleGetCountries]);

    useEffect(() => {
        if (props.deliveryAddressForm) {
            setDeliveryAddress(JSON.stringify(props.deliveryAddressForm));
        }
    }, [props]);

    const onHandleCreateOrder = async (values) => {
        if (!profile.deliveryAddress && !deliveryAddress) {
            setModalVisibility(true);
        } else {
            try {
                let orders = cartItems.flat().map((item) => {
                    const courierService = values[item.companyId];
                    const payload = {
                        products: [],
                        shopId: item.companyId,
                        serviceName: 'ATF Delivery',
                        serviceId: 1,
                        shippingFee: 2.5,
                    };
                    const products = {
                        price: item.product.productPrice,
                        productId: item.product.productId,
                        productQuantity: item.product.quantity,
                        weight: item.product.weightPerUnit,
                    };

                    //prettier-ignore
                    if (item.product.variations) products.variationId = item.product.variations[0].id;

                    payload.products.push(products);

                    return payload;
                });

                const payload = {
                    userId: profile.id,
                    deliveryName: profile.displayName,
                    deliveryAddress: deliveryAddress
                        ? deliveryAddress
                        : enrichedUserProfileAddress(),
                    mobileNumber: profile.mobileNumber,
                    email: profile.email,
                    orders,
                };
                const resp = await api.post.createOrder(payload);
                const { paymentReferenceNumber } = resp.data.result;
                history.push(pathnames.payment, { paymentReferenceNumber });
            } catch (error) {
                console.log(error);
                switch (error.response?.status) {
                    case 400: {
                        setErrorCode(400);
                        setErrorModalVisibility(true);
                        break;
                    }
                    case 800: {
                        setErrorCode(800);
                        setErrorModalVisibility(true);
                        break;
                    }
                    default: {
                        alert(error.response?.data?.message);
                    }
                }
            }
        }
    };

    const createNotification = (type, message, title) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(message);
                break;
        }
    };

    const onHandleUpdateAddress = async (values) => {
        setOnSubmit(true);
        if (!values.addressType || values.addressType == '') {
            values.addressType = 'Home';
        }
        try {
            const address = {
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                addressLineThree: values.addressLineThree,
                addressLineFour: values.addressLineFour,
                city: values.city,
                state: values.state,
                postcode: values.postcode,
                country: values.country,
                addressType: values.addressType,
            };

            var mobileNum = document.getElementById('mobile').value;
            const formData = new FormData();
            formData.append('id', id);
            formData.append('address', JSON.stringify(address));
            formData.append('name', values.name);
            formData.append('mobileNumber', mobileNum);
            await api.post.profileUpdate(formData);
            onHandleGetUserProfile(id);
            const formAddress = JSON.stringify(address);
            const oldCountry = '"country":' + JSON.parse(formAddress)['country'];
            const newCountry =
                '"country":' +
                JSON.stringify(
                    countries.filter((x) => x.value === JSON.parse(formAddress)['country'])[0]
                        ?.label
                );
            const newAddress = formAddress.replace(oldCountry, newCountry);
            const newAddress2 = newAddress.replace(',"addressType":""', '');
            setDeliveryAddress(newAddress2);
            setTimeout(() => {
                createNotification('success', 'Delivery Address Updated');
            }, 500);
        } catch (error) {
            console.log(error);
        } finally {
            setOnSubmit(false);
            setModalVisibility(false);
        }
    };

    const redirectCart = () => {
        setErrorModalVisibility(false);
        history.push(pathnames.cart);
    };

    const getCartItemsSubTotal = (cart) => {
        let total = 0;

        cart.forEach((i) => {
            total += i.product.quantity * i.product.productPrice;
        });

        return formatCurrency(total);
    };

    const onHandleOpenDeliveryFeeModal = useCallback((cart) => {
        deliveryFeeModalRef.current.onHandleGetDeliveryFee(cart, countries);
    }, []);

    return (
        <div className="checkout">
            <NotificationContainer />
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <BreadCrumb
                        paths={[
                            { onClick: () => history.push(pathnames.cart), text: 'My Cart' },
                            'Shipping Details',
                        ]}
                    />
                </div>
            </div>
            <form autoComplete="off" onSubmit={courierServiceForm.handleSubmit}>
                <div className="m-container m-container--center">
                    <div className="m-wrapper">
                        <div className="checkout__shipping-details">
                            <div className="checkout__shipping-content checkout__shipping-content--header">
                                <p className="m-txt m-txt--m m-txt--grey">Shipping Details</p>
                                <div
                                    className="checkout__edit m-txt"
                                    onClick={() => setModalVisibility(true)}
                                >
                                    <FiEdit2 size={20} />
                                    <p className="m-txt m-txt--bold">Edit</p>
                                </div>
                            </div>
                        </div>
                        <div className="checkout__shipping-details">
                            <div className="checkout__shipping-content">
                                <FiMapPin size={25} />
                                <div>
                                    <p className="m-txt m-txt--m">{profile.displayName}</p>
                                    <p className="m-txt">{userDeliveryAddress}</p>
                                </div>
                            </div>

                            <div className="checkout__shipping-content">
                                <BiReceipt size={25} />
                                <p className="m-txt">
                                    {deliveryAddress || profile.deliveryAddress
                                        ? 'Bill to the same address'
                                        : '-'}
                                </p>
                            </div>

                            <div className="checkout__shipping-content">
                                <FiPhoneCall size={25} />
                                <p className="m-txt">{profile.mobileNumber}</p>
                            </div>

                            <div className="checkout__shipping-content">
                                <FiMail size={25} />
                                <p className="m-txt">{profile.email}</p>
                            </div>
                        </div>
                        <div className="checkout__shipping-line" />
                        {cartItems.map((cart) => {
                            return cart.map((item, i) => {
                                const firstItem = i === 0;
                                const lastItemItem = i === cart.length - 1;
                                if (!item.product && item.products) {
                                    item.product = item.products[0];
                                }
                                return (
                                    <Fragment key={i}>
                                        {firstItem && (
                                            <div className="checkout__shopName">
                                                {/* prettier-ignore */}
                                                <p className="m-txt m-txt--white">{item.shopName}</p>
                                            </div>
                                        )}
                                        <div className="checkout__item">
                                            <div className="checkout__item-wrapper checkout__item-wrapper--product">
                                                {/* prettier-ignore */}
                                                <CircleAvatar src={item.product.productImage} size={55} />
                                                <div className="checkout__item-product">
                                                    {/* prettier-ignore */}
                                                    <p className="m-txt">{item.product.productName} /{' '}{item.product.weightPerUnit}kg</p>
                                                    {/* prettier-ignore */}
                                                    <p className="m-txt m-txt--s m-txt--grey">{getVariationStatus(item.product)}</p>
                                                    {/* prettier-ignore */}
                                                    <p className="m-txt m-txt--error m-txt--bold">RM{' '}{formatCurrency(item.product.productPrice)}</p>
                                                </div>
                                            </div>
                                            <div className="checkout__item-wrapper  checkout__item-wrapper--total">
                                                <div className="checkout__item-product">
                                                    {/* prettier-ignore */}
                                                    <p className="m-txt">Qty : {item.product.quantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {lastItemItem && (
                                            <div className="checkout__subtotal-price">
                                                {/* prettier-ignore */}
                                                <p className="m-txt m-txt--grey m-txt--bold">Shipping Option: 
                                                    <span type="button" 
                                                        className={getShippingOptionClassName({ error: courierServiceForm.errors[item.companyId] })} 
                                                        onClick={() => onHandleOpenDeliveryFeeModal(cart)}>{courierServiceForm.values?.[item.companyId]?.serviceName || "Select your courier service"} 
                                                    </span>
                                                    <FiChevronRight className="checkout__icon"/>
                                                </p>
                                                {/* prettier-ignore */}
                                                <p className="m-txt m-txt--grey m-txt--bold">Shipping fee: <span className="m-txt m-txt--bold">RM {formatCurrency(courierServiceForm.values?.[item.companyId]?.price) || "0.00"} </span></p>
                                                {/* prettier-ignore */}
                                                <p className="m-txt m-txt--grey m-txt--bold">Subtotal ({cart.length}{' '}{cart.length > 1 ? 'items' : 'item'}): <span className="m-txt m-txt--bold">{`RM ${getCartItemsSubTotal(cart)}`}</span></p>
                                            </div>
                                        )}
                                    </Fragment>
                                );
                            });
                        })}
                        <div className="checkout__total checkout__total--white">
                            <div className="checkout__total" />
                            <div className="checkout__total" />
                            <div className="checkout__total" />
                            <div className="checkout__total-price">
                                <p className="m-txt m-txt--m m-txt--bold m-txt--grey">
                                    Total to pay (Inc SST):
                                </p>
                            </div>
                            <div className="checkout__total-price">
                                <p className="m-txt m-txt--m m-txt--bold m-txt--grey">
                                    <span className="m-txt--error">RM {totalAmountToPay}</span>
                                </p>
                            </div>
                        </div>
                        <div className="checkout__button-wrapper checkout__button-wrapper--center">
                            {/* prettier-ignore */}
                            <Button type="submit" label="PLACE ORDER" />
                        </div>
                    </div>
                </div>
            </form>
            {/* prettier-ignore */}
            <DeliveryFeeModal ref={deliveryFeeModalRef} onHandleConfirm={courierServiceForm.setFieldValue} />

            <Modal
                center
                open={errorModalVisibility}
                onClose={() => redirectCart()}
                classNames={{ modal: 'w-1/2 errorModal' }}
            >
                <div className="address-modal-card bg-white w-full" style={{ height: '240px' }}>
                    {errorCode == 400 ? (
                        <img src={outOfStock} />
                    ) : errorCode == 800 ? (
                        <IoNotificationsOutline size={40} style={{ color: '#C19E66' }} />
                    ) : (
                        <IoNotificationsOutline size={40} style={{ color: '#C19E66' }} />
                    )}
                    <p className="font-medium text-center">
                        {errorCode == 400
                            ? 'This product no longer available'
                            : errorCode == 800
                            ? 'The Price / Shipping Fee has been updated, please try again.'
                            : null}
                    </p>
                    <label
                        className="font-bold"
                        style={{ color: '#C19E66', cursor: 'pointer' }}
                        onClick={() => redirectCart()}
                    >
                        OKAY
                    </label>
                </div>
            </Modal>
            <Modal
                center
                open={modalVisibility}
                onClose={() => setModalVisibility(false)}
                classNames={{ modal: 'w-1/2' }}
            >
                <div className="address-modal-card bg-white w-full h-screen">
                    <div className="mr-auto">
                        <p className="font-medium text-left text-2xl">Edit Shipping Details</p>
                    </div>
                    <Scrollbars>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validateSchema}
                            onSubmit={onHandleUpdateAddress}
                            classNames="w-1/2 h-screen"
                        >
                            {({ values, setFieldValue }) => (
                                <Form className="micro-address__form">
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="name"
                                            placeholder="Recipient’s name"
                                            label="Recipient’s name"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="name" />
                                        </p>
                                    </div>
                                    <div
                                        className="micro-address__input-wrapper"
                                        style={{
                                            borderBottom: '1px solid lightgrey',
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        <label
                                            className="m-text m-text--s text-input__label"
                                            htmlFor="name"
                                        >
                                            Phone Number
                                        </label>
                                        <div className="inputpad-group prefix mt-2">
                                            <TextInput
                                                id="mobile"
                                                outline
                                                max
                                                name="mobileNumber"
                                                placeholder="Mobile Number"
                                                disabled={onSubmit}
                                            />
                                        </div>
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="mobileNumber" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="addressLineOne"
                                            placeholder="Line 1"
                                            label="Address"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="addressLineOne" />
                                        </p>
                                        <TextInput
                                            max
                                            outline
                                            name="addressLineTwo"
                                            placeholder="Line 2"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="addressLineTwo" />
                                        </p>
                                        <TextInput
                                            max
                                            outline
                                            name="addressLineThree"
                                            placeholder="Line 3 (Optional)"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="addressLineThree" />
                                        </p>
                                        <TextInput
                                            max
                                            outline
                                            name="addressLineFour"
                                            placeholder="Line 4 (Optional)"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="addressLineFour" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <Dropdown
                                            outline
                                            max
                                            value={
                                                countries.filter(
                                                    (o) => o.value === values.country
                                                )[0]
                                            }
                                            options={countries}
                                            label="Country"
                                            disabled={onSubmit}
                                            onChange={(country) =>
                                                setFieldValue('country', country.value)
                                            }
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="country" />
                                        </p>
                                    </div>
                                    {values.country && values.country === 157 ? (
                                        <div className="account-address__input-wrapper">
                                            <Dropdown
                                                outline
                                                max
                                                value={
                                                    config.malaysia.state.filter(
                                                        (o) => o.value === values.state
                                                    )[0]
                                                }
                                                options={config.malaysia.state}
                                                label="State"
                                                disabled={onSubmit}
                                                onChange={(state) =>
                                                    setFieldValue('state', state.value)
                                                }
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="state" />
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="account-address__input-wrapper">
                                            <TextInput
                                                max
                                                outline
                                                name="state"
                                                placeholder="States"
                                                label="States"
                                                disabled={onSubmit}
                                            />
                                            <p className="m-txt m-txt--s m-txt--error">
                                                <ErrorMessage name="state" />
                                            </p>
                                        </div>
                                    )}
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="city"
                                            placeholder="City"
                                            label="City"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="city" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper">
                                        <TextInput
                                            max
                                            outline
                                            name="postcode"
                                            placeholder="Zip/Postal code"
                                            label="Zip/Postal code"
                                            disabled={onSubmit}
                                        />
                                        <p className="m-txt m-txt--s m-txt--error">
                                            <ErrorMessage name="postcode" />
                                        </p>
                                    </div>
                                    <div className="micro-address__input-wrapper mb-6">
                                        <RadioGroup
                                            row
                                            aria-label="addressType"
                                            name="addressType"
                                            defaultValue={
                                                initialValues.addressType
                                                    ? initialValues.addressType
                                                    : 'Home'
                                            }
                                            onChange={(e) =>
                                                setFieldValue('addressType', e.target.value)
                                            }
                                        >
                                            <FormControlLabel
                                                value="Home"
                                                control={<Radio color="default" />}
                                                label="Home"
                                            />
                                            <FormControlLabel
                                                value="Office"
                                                control={<Radio color="default" />}
                                                label="Office"
                                            />
                                        </RadioGroup>
                                    </div>
                                    <div className="cart__button-wrapper">
                                        <label
                                            className="self-center m-auto cursor-pointer"
                                            onClick={() => setModalVisibility(false)}
                                        >
                                            CANCEL
                                        </label>
                                        <Button
                                            type="submit"
                                            label="SAVE"
                                            text
                                            gold
                                            disabled={onSubmit}
                                            style={{ boxShadow: 'none' }}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Scrollbars>
                </div>
            </Modal>
        </div>
    );
};

export default CheckoutPage;

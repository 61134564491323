import * as yup from 'yup';
import moment from 'moment';

export const orderStatusFormat = (statusCode) => {
    let status = {};

    switch (statusCode) {
        case 'pendingPayment':
            status = {
                label: 'Pending payment',
                color: 'grey2',
                message: 'Pending Payment',
                barTitle: 'Pending payment',
            };
            break;
        case 'toPack':
            status = {
                label: 'To pack',
                color: 'gold',
                message: 'Payment success',
                barTitle: 'To pack',
            };
            break;
        case 'packing':
            status = {
                label: 'To Ship',
                color: 'gold',
                message: 'Seller packing your order',
                barTitle: 'To Ship',
            };
            break;
        case 'shipped':
            status = {
                label: 'To receive',
                color: 'gold',
                message: 'Seller shipped your order',
                barTitle: 'To receive',
            };
            break;
        case 'delivered':
            status = {
                label: 'Delivered',
                color: 'green',
                message: 'Your order has been delivered',
                barTitle: 'Delivered',
            };
            break;
        case 'cancelled':
            status = {
                label: 'Cancelled',
                color: 'red',
                message: 'Seller canceled your order',
                barTitle: 'Order cancelled by seller.',
            };
            break;
        case 'cancelledByCustomer':
            status = {
                label: 'Cancelled',
                color: 'red',
                message: 'You have canceled the order',
                barTitle: 'Order cancelled',
            };
            break;
        case 'refunded':
            status = {
                label: 'Refunded',
                color: 'green',
                message: 'Your order has been refunded',
                barTitle: 'Order refunded',
            };
            break;
        default:
            status = {
                label: 'To pack',
                color: 'gold',
                message: 'Payment success',
                barTitle: 'To pack',
            };
            break;
    }

    return status;
};

export const yupPasswordConfig = (fieldName = 'Password') =>
    yup
        .string()
        .matches(
            /^(?=.{8,18})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\]{};':".<>?]).*$/,
            `${fieldName} requires at least 1 Uppercase, 1 Lowercase, 1 Special Character and must be between 8 to 18 characters.`
        )
        .required(`${fieldName} is required.`);

export const formatPhoneNumber = (phoneNumber) => {
    let formatted = phoneNumber.replace(' ', '').replace(' ', '');
    if (formatted.substring(0, 2) !== '+6') {
        formatted = '+6' + formatted;
    }
    if (formatted.length >= 12) {
        return formatted.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3');
    } else if (formatted.length <= 3) {
        return '+60';
    } else {
        return phoneNumber;
    }
};

export const validMobileNumbers = (value) => {
    if (!value) return false;
    const mobileRegex = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/g;
    const validMobileNumbers = value.match(mobileRegex);
    return validMobileNumbers;
};

export const sanitizeError = (e) => {
    let errorObj = e;
    if (typeof e === 'object') {
        if (!!e.response) {
            if (!!e.response.data) {
                errorObj = e.response.data;
            } else {
                errorObj = e.response;
            }
        } else if (!!e.data) {
            errorObj = e.data;
        }

        return errorObj.message;
    } else {
        errorObj = e;
        return errorObj;
    }
};

export const parseQueryString = function (str) {
    const objURL = {};

    str.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function ($0, $1, $2, $3) {
        objURL[$1] = $3;
    });
    return objURL;
};

export const formatCurrency = (num) => {
    if (!num) return '0.00';
    return Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const getVariationStatus = (o) => {
    if (o.variations) {
        const opt = o.variations[0];
        const optOne = opt.variations.variationOne;
        const optTwo = opt.variations.variationTwo;

        if (optOne && optTwo) {
            return optOne + ` - ${optTwo}`;
        } else {
            return optOne;
        }
    }
};

export const currencyFormat = (num) => {
    if (!num) return '0.00';
    return Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatDate = (date, format) => {
    let formattedDate = moment(date).calendar(null, {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'DD MMM YYYY',
        nextWeek: 'DD MMM YYYY',
        sameElse: 'DD MMM YYYY',
    });

    if (format) {
        formattedDate = moment(date).format(format);

        if (format === 'timestamp') {
            formattedDate = moment(date).unix() * 1000;
        }
    }

    return formattedDate;
};

export const formatStreamDate = (date, format) => {
    let formattedDate = moment(date).calendar(null, {
        lastDay: '[Yesterday] H:mm',
        sameDay: '[Today] H:mm',
        nextDay: '[Tomorrow] H:mm',
        lastWeek: 'DD MMM YYYY H:mm',
        nextWeek: 'DD MMM YYYY H:mm',
        sameElse: 'DD MMM YYYY H:mm',
    });

    if (format) {
        formattedDate = moment(date).format(format);

        if (format === 'timestamp') {
            formattedDate = moment(date).unix() * 1000;
        }
    }

    return formattedDate;
};

export const getStringAddress = (userAddress) => {
    if (!userAddress) return '';

    let address = [
        userAddress?.addressLineOne,
        userAddress?.addressLineTwo,
        userAddress?.addressLineThree,
        userAddress?.addressLineFour,
        userAddress?.postcode,
        userAddress?.country,
        userAddress?.state,
        userAddress?.city,
    ]
        .filter(Boolean)
        .join(', ');

    return address;
};

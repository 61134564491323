import React, { Fragment, useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';

import api from 'services/api';
import Button from 'components/button/button';

const DeliveryFeeModal = forwardRef(({ onHandleConfirm }, ref) => {
    const cartsItems = useRef();
    const countriesData = useRef();
    const [visible, setVisible] = useState(false);
    const { profile } = useSelector((state) => state.user);
    const [isFetching, setIsFetching] = useState(false);
    const [couriers, setCouriers] = useState(null);
    const [error, setIsError] = useState(false);

    useImperativeHandle(ref, () => ({
        onHandleGetDeliveryFee: getDeliveryFee,
    }));

    const onHandleVisibleDeliveryFeeModal = (boolean) => {
        setVisible(boolean);
    };

    const onHandleDimissModal = () => {
        cartsItems.current = null;
        setIsError(false);
        setIsFetching(false);
        setCouriers(null);
        onHandleVisibleDeliveryFeeModal(false);
    };

    const onHandleSelectCourierService = (o) => {
        const companyId = cartsItems.current[0].companyId;
        onHandleConfirm(companyId, o);
        onHandleDimissModal();
    };

    const getDeliveryFee = async (carts, countries) => {
        cartsItems.current = carts;
        countriesData.current = countries;
        const adddress = profile.address;
        let response = null;

        onHandleVisibleDeliveryFeeModal(true);
        setCouriers(null);
        setIsError(false);
        setIsFetching(true);

        const getStores = () => {
            let shopId = null;
            let weight = 0;
            let totalQuantityOfProduct = 0;

            carts?.forEach((o) => {
                shopId = o.companyId;
                weight += o.product.quantity * o.product.weightPerUnit;
                totalQuantityOfProduct += o.product.quantity;
            });

            return [{ shopId, totalQuantityOfProduct, weight }];
        };

        try {
            const payload = {
                customerCountry: countries
                    ? countries.filter((o) => o.value !== adddress.country)?.[0]?.label
                    : 'Malaysi',
                customerPostCode: adddress.postcode,
                customerState: adddress.state,
                stores: getStores(),
            };
            response = await api.post.deliveryFee(payload);
        } catch (error) {
            console.log(error);
            setIsError(true);
        } finally {
            setIsFetching(false);
        }

        if (response) {
            setCouriers(response.data.result?.[0]?.couriers);
        }
    };

    return (
        //prettier-ignore
        <Modal center open={visible} onClose={onHandleDimissModal} classNames={{ modal: 'delivery-fee-modal' }}>
            <div className="delivery-fee-modal__container">
                <div className="delivery-fee-modal__header">
                    <p className="m-txt m-txt--bold m-txt--m">Select shipping option</p>
                </div>
                {isFetching && (
                    <Fragment>
                        <div className="delivery-fee-modal__loading" />
                        <div className="delivery-fee-modal__loading" />
                    </Fragment>
                )}
                {(error || (couriers && !couriers.length)) && (
                    <div className="delivery-fee-modal__empty">
                        <p className="m-txt">No courier service available</p>
                        {/*prettier-ignore */}
                        <Button label="Try again" small onClick={() => getDeliveryFee(cartsItems.current, countriesData.current)} />
                    </div>
                )}
                <ul className="delivery-fee-modal__list">
                    {couriers?.map((o, i) => {
                        return (
                            //prettier-ignore
                            <li className="delivery-fee-modal__item" key={i} onClick={() => onHandleSelectCourierService(o)}>
                                {/*prettier-ignore */}
                                <div className="delivery-fee-modal__logo" style={{ backgroundImage: `url(${o.logo})` }} />
                                <div className="delivery-fee-modal__content">
                                    <p className="m-txt m-txt--bold m-txt--s">{o.serviceName}</p>
                                    <p className="m-txt m-txt--s m-txt--grey">{o.deliveryTime}</p>
                                </div>
                                <div className="delivery-fee-modal__price">
                                    <p className="m-txt m-txt--bold m-txt--s">RM {o.price}</p>
                                    <FiChevronRight />
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div className="delivery-fee-modal__footer">
                    {/*prettier-ignore */}
                    <p className="m-txt m-txt--bold m-txt--grey" onClick={onHandleDimissModal}>Cancel</p>
                </div>
            </div>
        </Modal>
    );
});

export default DeliveryFeeModal;

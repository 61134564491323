import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'services/api';
import { pathnames } from 'routes/routes';
import { Spinner } from 'components/icons/Spinner';

const PaymentPage = ({ location }) => {
    const history = useHistory();
    const paymentReferenceNumber = location?.state?.paymentReferenceNumber;
    const orderNumber = location?.state?.orderNumber;

    useEffect(() => {
        getPaymentGateway();
    }, []);

    const getPaymentGateway = async () => {
        try {
            let response = null;

            if (paymentReferenceNumber) response = await api.get.pay(paymentReferenceNumber);

            if (orderNumber) response = await api.get.payNow(orderNumber);

            window.location = response.data.result;
        } catch (error) {
            alert(error);
            history.push(pathnames.cart);
        }
    };

    return (
        <div className="payment">
            <div className="payment__loading">
                <Spinner />
            </div>
        </div>
    );
};

export default PaymentPage;

import { axiosService } from './axiosService';
import constanst from '../helpers/constants';

const endpointUrl = process.env.REACT_APP_API_ENDPOINT;
const mediaEndpointUrl = process.env.REACT_APP_MEDIA_ENDPOINT;

const request = {
    login: endpointUrl + 'user/buyer/login',
    googleLogin: endpointUrl + 'sso/login/google',
    facebookLogin: endpointUrl + 'sso/login/facebook',
    home: endpointUrl + 'user/buyer',
    notificationSummary: endpointUrl + 'helper/notificationSummary/{userId}',
    signup: endpointUrl + 'user/register',
    activeteUser: endpointUrl + 'user/activate',
    resendActivationCode: endpointUrl + 'user/resendActivationCode/{userId}/{platform}',
    resetPassword: endpointUrl + 'user/resetPassword',
    forgetPassword: endpointUrl + 'user/forgotPassword',
    validatePasswordToken: endpointUrl + 'user/validatePasswordToken',
    profile: endpointUrl + 'user/buyer/profile/{userId}',
    categories: endpointUrl + 'helper/category',
    products: endpointUrl + 'product/buyer',
    visitStore: endpointUrl + 'product/buyer/visitStore/{companyId}',
    refreshToken: endpointUrl + 'user/refreshJwt',
    profileUpdate: endpointUrl + 'user/buyer/update',
    announcement: endpointUrl + 'stream/buyer/announcement/list',
    orderStatus: endpointUrl + 'orders/buyer/{userId}',
    orders: endpointUrl + 'orders/buyer/{userId}/status/{status}',
    rateProduct: endpointUrl + 'helper/rating/rate',
    recentView: endpointUrl + 'helper/recentView/{userId}',
    wishlist: endpointUrl + 'helper/wishlist/{userId}',
    wishlistPost: endpointUrl + 'helper/wishlist',
    myReview: endpointUrl + 'helper/rating/myReview/{userId}',
    toReview: endpointUrl + 'helper/rating/toReview/{userId}',
    productDetails: endpointUrl + 'product/buyer/{productId}',
    buyNow: endpointUrl + 'cart/buyer/buyNow',
    myCart: endpointUrl + 'cart/buyer/{userId}',
    updateCart: endpointUrl + 'cart/buyer/addToCart',
    removeCart: endpointUrl + 'cart/buyer/removeMultiple',
    countries: endpointUrl + 'helper/country',
    notifications: endpointUrl + 'orders/buyer/orderNotification/{userId}',
    joinStream: endpointUrl + 'stream/buyer',
    likePost: endpointUrl + 'stream/buyer/announcement/like',
    getViewers: mediaEndpointUrl + '/LiveApp/rest/v2/broadcasts/list/0/50',
    getStreamData: mediaEndpointUrl + '/LiveApp/rest/v2/broadcasts/{streamId}',
    productReviews: endpointUrl + 'helper/rating/{productId}',
    createOrder: endpointUrl + 'orders/buyer/create',
    initiatePayment: endpointUrl + 'payment/pay/{paymentReferenceNumber}',
    secondPayment: endpointUrl + 'payment/payNow/{orderNumber}',
    getCourier: endpointUrl + 'courier/',
    notification: endpointUrl + 'notification/customer/{userId}',
    readNotification: endpointUrl + 'notification/customer/read',
    cancelOrder: endpointUrl + 'orders/buyer/cancel',
    orderDetails: endpointUrl + 'orders/buyer/get/{orderNumber}',
    announcementDetails: endpointUrl + 'stream/buyer/announcement/{streamKey}',
    pay: endpointUrl + 'payment/pay/web/',
    payNow: endpointUrl + 'payment/payNow/web/',
    payStatus: endpointUrl + 'payment/status/paymentReferenceNumber/',
    payNowStatus: endpointUrl + 'payment/status/orderNumber/',
    deliveryFee: endpointUrl + 'delivery/deliveryFee',
};

const api = {
    get: {
        home: () => {
            const endpoint = request.home;
            return axiosService.get(endpoint);
        },
        notificationSummary: (id) => {
            const endpoint = request.notificationSummary.replace('{userId}', id);
            return axiosService.get(endpoint);
        },
        resendActivationCode: ({ userId }) => {
            const endpoint = request.resendActivationCode
                .replace('{userId}', userId)
                .replace('{platform}', constanst.PLATFORM);
            return axiosService.get(endpoint);
        },
        profile: (payload) => {
            const endpoint = request.profile.replace('{userId}', payload);
            return axiosService.get(endpoint);
        },
        orderStatus: (payload) => {
            const endpoint = request.orderStatus.replace('{userId}', payload);
            return axiosService.get(endpoint);
        },
        orders: ({ id, status }) => {
            const endPointUserId = request.orders.replace('{userId}', id);
            const endpoint = endPointUserId.replace('{status}', status);
            return axiosService.get(endpoint);
        },
        recentView: (payload) => {
            const endpoint = request.recentView.replace('{userId}', payload);
            return axiosService.get(endpoint);
        },
        wishlist: (payload) => {
            const endpoint = request.wishlist.replace('{userId}', payload);
            return axiosService.get(endpoint);
        },
        myReview: ({ id, params }) => {
            const endpoint = request.myReview.replace('{userId}', id);
            return axiosService.get(endpoint, params);
        },
        toReview: (id) => {
            const endpoint = request.toReview.replace('{userId}', id);
            return axiosService.get(endpoint);
        },
        myCart: (payload) => {
            const endpoint = request.myCart.replace('{userId}', payload);
            return axiosService.get(endpoint);
        },
        productDetails: (payload) => {
            const endpoint = request.productDetails.replace('{productId}', payload);
            return axiosService.get(endpoint);
        },
        productReviews: (id, payload) => {
            const endpoint = request.productReviews.replace('{productId}', id);
            return axiosService.get(endpoint, payload);
        },
        products: (params) => {
            let endPoint = request.products;
            return axiosService.get(endPoint, params);
        },
        visitStore: (payload) => {
            let endPoint = request.visitStore.replace('{companyId}', payload);
            return axiosService.get(endPoint);
        },
        announcement: (payload) => {
            const endpoint = request.announcement;
            return axiosService.get(endpoint, payload);
        },
        countries: () => axiosService.get(request.countries),
        categories: () => axiosService.get(request.categories),
        viewers: () => axiosService.get(request.getViewers),
        getStreamData: (streamId) => {
            const endpoint = request.getStreamData.replace('{streamId}', streamId);
            return axiosService.getWithoutToken(endpoint);
        },
        initiatePayment: (paymentReferenceNumber) => {
            const endppoint = request.initiatePayment.replace(
                '{paymentReferenceNumber}',
                paymentReferenceNumber
            );
            return axiosService.get(endppoint);
        },
        secondPayment: (orderNumber) => {
            const endppoint = request.orderPayment.replace('{orderNumber}', orderNumber);
            return axiosService.get(endppoint);
        },
        streams: () => {
            const endpoint = request.streams;
            return axiosService.get(endpoint);
        },
        getCourier: () => {
            const endpoint = request.getCourier;
            return axiosService.get(endpoint);
        },
        notifications: (userId, payload) => {
            const endpoint = request.notification.replace('{userId}', userId);
            return axiosService.get(endpoint, payload);
        },
        getOrderDetails: (orderNumber) => {
            const endpoint = request.orderDetails.replace('{orderNumber}', orderNumber);
            return axiosService.get(endpoint);
        },
        announcementDetails: (payload) => {
            const endpoint = request.announcementDetails.replace('{streamKey}', payload);
            return axiosService.get(endpoint);
        },
        pay: (payload) => axiosService.get(`${request.pay}${payload}`),
        payNow: (payload) => axiosService.get(`${request.payNow}${payload}`),
        payStatus: (payload) => axiosService.get(`${request.payStatus}${payload}`),
        payNowStatus: (payload) => axiosService.get(`${request.payNowStatus}${payload}`),
    },
    post: {
        login: (payload) => axiosService.post(request.login, payload),
        googleLogin: (payload) => axiosService.post(request.googleLogin, payload),
        facebookLogin: (payload) => axiosService.post(request.facebookLogin, payload),
        forgetPassword: (payload) => {
            payload.platform = constanst.PLATFORM;
            return axiosService.post(request.forgetPassword, payload);
        },
        validatePasswordToken: (payload) => {
            payload.platform = constanst.PLATFORM;
            return axiosService.post(request.validatePasswordToken, payload);
        },
        resetPassword: (payload) => {
            payload.platform = constanst.PLATFORM;
            return axiosService.post(request.resetPassword, payload);
        },
        activateUser: (payload) => {
            payload.platform = constanst.PLATFORM;
            axiosService.post(request.activeteUser, payload);
        },
        signup: (payload) => {
            payload.role = constanst.ROLE;
            return axiosService.post(request.signup, payload);
        },
        readNotification: (payload) => {
            return axiosService.post(request.readNotification, payload);
        },
        refreshToken: (payload) => axiosService.post(request.refreshToken, payload),
        profileUpdate: (payload) => axiosService.post(request.profileUpdate, payload),
        updateCart: (payload) => axiosService.post(request.updateCart, payload),
        removeCart: (payload) => axiosService.post(request.removeCart, payload),
        rateProduct: (payload) => axiosService.post(request.rateProduct, payload),
        wishlistPost: (payload) => axiosService.post(request.wishlistPost, payload),
        likePost: (payload) => axiosService.post(request.likePost, payload),
        createOrder: (payload) => axiosService.post(request.createOrder, payload),
        joinStream: (payload) => axiosService.post(request.joinStream, payload),
        buyNow: (payload) => axiosService.post(request.buyNow, payload),
        cancelOrder: (payload) => axiosService.post(request.cancelOrder, payload),
        deliveryFee: (payload) => axiosService.post(request.deliveryFee, payload),
    },
    delete: {},
    put: {},
};

export default api;

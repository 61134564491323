import React, { Fragment, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FiCircle, FiTrash2 } from 'react-icons/fi';
import { IoChevronForwardOutline, IoCheckmarkSharp } from 'react-icons/io5';
import Modal from 'react-responsive-modal';
import _ from 'lodash';

import Button from 'components/button/button';
import QuantityPicker from 'components/quantity-picker/quantity-picker';
import { useIsMount } from 'hooks/use-mount';
import CircleAvatar from 'components/circle-avatar/circle-avatar';
import { formatCurrency, getVariationStatus } from 'helpers/utility';
import withCart from 'context/with-cart';
import { pathnames } from 'routes/routes';
import api from 'services/api';
import noProductIcon from 'assets/notfound/empty-cart-icon.png';
import removeProductIcon from 'assets/products/remove-product.png';
import { getUserNotificationSummary } from 'redux/actions/user-actions';
import { currencyFormat } from 'helpers/utility';

const CartPage = (props) => {
    const dispatch = useDispatch();
    const [initSelecteAll, setInitSelectAll] = useState(false);
    const isMount = useIsMount();
    const history = useHistory();
    const location = useLocation();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [cart, setCart] = useState([]);
    const [selected, setSelected] = useState([]);
    const [totalCartItem, setTotalCartItem] = useState(0);
    const { id } = useSelector((state) => state.user.profile);

    const onHandleCheckout = () => {
        const checkoutOrder = [];
        let item = null;

        cart.forEach((o) => {
            const shopName = o.shopName;
            const companyId = o.shopId;
            const orderItems = [];

            o.cartItems.forEach((j, i) => {
                const variantProduct = j.variations;
                const productId = j.productId;
                const product = o.cartItems[i];

                if (variantProduct) {
                    item = selected.findIndex((k) => k.variationId === variantProduct[0].id);
                } else {
                    item = selected.findIndex((k) => !k.variationId && k.productId === productId);
                }

                if (item > -1) {
                    checkoutOrder.push({ shopName, companyId, product: product });
                }
            });

            if (orderItems.length > 0) {
                checkoutOrder.push({ shopName, companyId, products: orderItems });
            }
        });

        const checkout = {
            orders: checkoutOrder,
            total: onHandleGetTotal(),
        };
        history.push('/checkout', { checkout });
    };

    const onhandleGetCart = useCallback(async () => {
        try {
            let total = 0;
            const response = await api.get.myCart(id);
            const data = response.data.result;
            data.forEach((o) => {
                total += o.cartItems.length;
            });
            setCart(data);
            setTotalCartItem(total);
        } catch (error) {}
    }, [id]);

    const onHandleSelectAll = useCallback(
        (deselect) => {
            let nextCarts = [];

            if (!deselect) {
                const carts = cart.map((o) => {
                    return o.cartItems.map((p) => {
                        if (p.stock === 0) return {};
                        let product = {
                            productId: p.productId,
                            variationId: null,
                            quantity: p.quantity,
                            price: p.productPrice,
                            shopId: o.shopId,
                            stock: p.stock,
                        };
                        if (p.variations) {
                            product.variationId = p.variations[0].id;
                        }

                        return product;
                    });
                });
                nextCarts = [].concat.apply([], carts).filter((item) => Object.keys(item).length);
            }

            setSelected(nextCarts);
        },
        [cart]
    );

    const onHandleGetNotificationSummary = useCallback(
        (o) => dispatch(getUserNotificationSummary(o)),
        [dispatch]
    );

    useEffect(() => {
        /* On Init Select All Cart */
        if (cart.length && !initSelecteAll) {
            setInitSelectAll(true);
            onHandleSelectAll();
        }
    }, [cart, initSelecteAll, onHandleSelectAll]);

    useEffect(() => {
        if (isMount) {
            onhandleGetCart();
        }
    }, [isMount, onhandleGetCart]);

    const onHandleQuantityChange = (quantity, item, deleteIt) => {
        let payload = null;

        if (quantity <= 0 && !deleteIt) {
            onHandleShowConfirmModal(true);
            const deleteItemPayload = {
                userId: id,
                productId: item.productId,
                quantity: quantity,
                variationId: item.variations ? item.variations[0].id : null,
            };
            setSelected([deleteItemPayload]);
            return;
        }

        if (deleteIt) {
            payload = selected;
        } else {
            payload = {
                userId: id,
                productId: item.productId,
                quantity: quantity,
                variationId: item.variations ? item.variations[0].id : null,
                updateAction: true,
            };
        }

        props.onHandleUpdateCart(payload, () => {
            onHandleUpdateSelectedCart(payload);
            onhandleGetCart();
        });
    };

    const debounceQuantityChange = _.debounce(onHandleQuantityChange, 800);

    const onHandleDeleteItem = async () => {
        const cartProducts = selected.map((o) => ({
            productId: o.productId,
            variationId: o.variationId,
        }));
        let payload = {
            cartProducts,
            userId: id,
        };
        try {
            await api.post.removeCart(payload);
            onhandleGetCart();
            setSelected([]);
            onHandleGetNotificationSummary(id);
        } catch (error) {}
    };

    const onHandleSelectCart = (j) => {
        const currentCart = selected.length ? selected.slice() : [];
        let cartIndex = null;
        if (j.stock === 0) {
            const product = {
                productId: j.variations ? j.variations[0]?.productId : j.productId,
                variationId: j.variations ? j.variations[0]?.id : null,
            };
            setSelected([product]);
            onHandleShowConfirmModal(true);
            return;
        }

        if (j.variations) {
            cartIndex = currentCart.findIndex(
                (o) => o.variationId && o.variationId === j.variations[0].id
            );
        } else {
            cartIndex = currentCart.findIndex((o) => !o.variationId && o.productId === j.productId);
        }

        if (cartIndex > -1) {
            currentCart.splice(cartIndex, 1);
        } else {
            const product = {
                productId: j.productId,
                variationId: j.variations ? j.variations[0].id : null,
                quantity: j.quantity,
                price: j.productPrice,
                shopId: j.shopId,
                stock: j.stock,
            };
            currentCart.push(product);
        }

        setSelected(currentCart);
    };

    const onHandleGetSelected = (j) => {
        if (selected && selected.length) {
            let cartIndex = null;

            if (j.variations) {
                cartIndex = selected.findIndex(
                    (o) => o.variationId && o.variationId === j.variations[0].id
                );
            } else {
                cartIndex = selected.findIndex(
                    (o) => !o.variationId && o.productId === j.productId
                );
            }

            if (cartIndex > -1) {
                return true;
            }
        }
        return false;
    };

    const onHandleUpdateSelectedCart = (item) => {
        const currentCart = selected.length ? selected.slice() : [];
        let cartIndex = null;
        if (!currentCart.length) return;

        if (item.variationId) {
            cartIndex = currentCart.findIndex(
                (o) => o.variationId && o.variationId === item.variationId
            );
        } else {
            cartIndex = currentCart.findIndex(
                (o) => !o.variationId && o.productId === item.productId
            );
        }

        if (item.quantity === 0) {
            currentCart.splice(cartIndex, 1);
        } else {
            if (cartIndex > -1) {
                currentCart[cartIndex].quantity = item.quantity;
            }
        }

        setSelected(currentCart);
    };

    const onHandleGetTotal = () => {
        if (selected.length) {
            let total = 0;
            selected.forEach((o) => (total += o.price * o.quantity));
            return parseFloat(total);
        }
        return 0;
    };

    const onHandleShowConfirmModal = (o, deleteIt) => {
        try {
            if (deleteIt) {
                onHandleDeleteItem();
                setShowConfirmDelete(false);
            } else {
                setShowConfirmDelete(o);
            }

            if (!o) {
                console.log(selected);
                const removedEmptyStock = selected.filter((o) => o.stock && o.stock !== 0);
                setSelected(removedEmptyStock);
            }
        } catch (error) {
            alert(error);
        }
    };

    const onHandleNavigateProduct = (id) => {
        const path = pathnames.productDetails.replace(':productId', id);
        history.push(path, { from: location.pathname });
    };

    return (
        <div className="cart">
            <DeleteCartConfirmModal
                isOpen={showConfirmDelete}
                onHandleVisible={onHandleShowConfirmModal}
            />
            <div className="m-container m-container--center">
                <div className="m-wrapper">
                    <h1 className="m-txt m-txt--xl m-txt--bold">My Cart</h1>
                    <div className="cart__item-wrapper cart__item-wrapper--delete justify-end w-full">
                        {selected.length ? (
                            <p
                                className="m-txt m-txt--error"
                                onClick={() => setShowConfirmDelete(true)}
                            >
                                <FiTrash2 size={20} /> Remove selected ({selected.length})
                            </p>
                        ) : null}
                    </div>

                    {cart.map((o, i) => {
                        const key = `${i}-cart`;
                        return (
                            <div
                                key={key}
                                style={{ border: '1px solid lightgrey' }}
                                className="mt-4"
                            >
                                <div className="cart__card-header mt-0">
                                    <Link className="cart__link" to={`/merchant/${o.shopId}`}>
                                        <span className="m-txt font-normal">{o.shopName}</span>
                                        <IoChevronForwardOutline size={20} />
                                    </Link>
                                </div>
                                {o.cartItems.map((j, k) => {
                                    const itemKey = `${k}-cart-item`;
                                    const actived = onHandleGetSelected(j);
                                    const outOfStock = j.stock === 0 ? ` cart__item--disabled` : '';
                                    j.shopId = o.shopId;

                                    return (
                                        <div
                                            className={`cart__item${outOfStock}`}
                                            key={itemKey}
                                            style={{ borderBottom: '1px solid lightgrey' }}
                                            onClick={() =>
                                                outOfStock ? onHandleSelectCart(j) : null
                                            }
                                        >
                                            <div
                                                className={`cart__item-wrapper cart__item-wrapper--product`}
                                            >
                                                {actived ? (
                                                    <IoCheckmarkSharp
                                                        className="cart__item-icon cart__item-icon--active"
                                                        size={20}
                                                        onClick={() => onHandleSelectCart(j)}
                                                        style={
                                                            outOfStock
                                                                ? { pointerEvents: 'none' }
                                                                : null
                                                        }
                                                    />
                                                ) : (
                                                    <FiCircle
                                                        className="cart__item-icon"
                                                        size={20}
                                                        onClick={() => onHandleSelectCart(j)}
                                                        style={
                                                            outOfStock
                                                                ? { pointerEvents: 'none' }
                                                                : null
                                                        }
                                                    />
                                                )}
                                                <CircleAvatar src={j.productImage} size={55} />
                                                <div
                                                    className="cart__item-product"
                                                    onClick={() =>
                                                        onHandleNavigateProduct(j.productId)
                                                    }
                                                    style={
                                                        outOfStock
                                                            ? { pointerEvents: 'none' }
                                                            : null
                                                    }
                                                >
                                                    <p className="m-txt font-medium">
                                                        {j.productName} / {j.weightPerUnit}kg
                                                    </p>
                                                    <p className="m-txt m-txt--s m-txt--grey font-normal">
                                                        {getVariationStatus(j)}
                                                    </p>
                                                    <p className="m-txt m-txt--error font-normal">
                                                        RM {currencyFormat(j.productPrice)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="cart__item-wrapper cart__item-wrapper--quantity"
                                                style={
                                                    outOfStock ? { pointerEvents: 'none' } : null
                                                }
                                            >
                                                <QuantityPicker
                                                    value={j.quantity}
                                                    max={j.stock}
                                                    onChange={(q) => debounceQuantityChange(q, j)}
                                                />
                                            </div>
                                            <div className="cart__item-wrapper  cart__item-wrapper--total">
                                                <div className="cart__item-product">
                                                    <p className="m-txt m-txt--grey m-txt--bold">
                                                        {outOfStock
                                                            ? 'OUT OF STOCK'
                                                            : `RM ${currencyFormat(
                                                                  j.quantity * j.productPrice
                                                              )}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    {totalCartItem ? (
                        <Fragment>
                            <div className="cart__total">
                                <div className="cart__item-wrapper">
                                    {totalCartItem === selected.length ? (
                                        <IoCheckmarkSharp
                                            size={20}
                                            className="cart__item-icon"
                                            onClick={() => onHandleSelectAll(true)}
                                        />
                                    ) : (
                                        <FiCircle
                                            size={20}
                                            className="cart__item-icon"
                                            onClick={() => onHandleSelectAll(false)}
                                        />
                                    )}
                                    <p className="m-txt font-medium">Select All</p>
                                </div>
                                <div className="cart__total-price">
                                    <p className="m-txt m-txt--grey font-medium text-right">
                                        Subtotal:{' '}
                                    </p>
                                    <p className="m-txt m-txt--bold">
                                        RM {formatCurrency(onHandleGetTotal())}
                                    </p>
                                </div>
                            </div>
                            <div className="cart__total cart__total--white">
                                <div className="cart__item-wrapper"></div>
                                <div className="cart__total-price">
                                    <p className="m-txt m-txt--grey m-txt--bold text-right">
                                        Subtotal:{' '}
                                    </p>
                                    <p className="m-txt--error">
                                        RM {formatCurrency(onHandleGetTotal())}
                                    </p>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="home__products home__products--white container m-container m-container--center mt-0">
                            <div className="m-wrapper">
                                <img
                                    src={noProductIcon}
                                    className="m-auto mt-40 mb-4 w-12"
                                    alt=""
                                />
                                <p className="text-center mb-4 text-gray-400">Your cart is empty</p>
                                <div className="text-center m-auto mb-40">
                                    <Button
                                        label="View Products"
                                        gold
                                        small
                                        onClick={() => history.push(pathnames.productCategory)}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {cart.length ? (
                        <div className="cart__button-wrapper cart__button-wrapper--center">
                            <Button
                                label="CHECK OUT"
                                onClick={onHandleCheckout}
                                style={{ marginLeft: 'auto' }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default withCart(CartPage);

const DeleteCartConfirmModal = ({ isOpen, onHandleVisible }) => {
    return (
        <Modal
            center
            open={isOpen}
            classNames={{ root: 'cart__confirm-modal', overlay: 'cart__overlay' }}
            showCloseIcon={false}
            onClose={() => onHandleVisible(false)}
        >
            <div className="cart__card bg-white h-auto">
                <img src={removeProductIcon} className="h-16 mt-4 mb-4" alt="" />
                <p className="m-txt cart__modal-label text-black font-medium font-sans">
                    Are you sure you want to remove selected item(s) from your cart?
                </p>
                <div className="cart__button-wrapper">
                    <Button
                        label="CANCEL"
                        text
                        style={{ boxShadow: 'none' }}
                        onClick={() => onHandleVisible(false)}
                    />
                    <Button
                        label="REMOVE"
                        textRed
                        style={{ boxShadow: 'none' }}
                        onClick={() => onHandleVisible(false, true)}
                    />
                </div>
            </div>
        </Modal>
    );
};
